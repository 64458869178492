/**
 * Basic typography style for copy text
 */
body {
	color: $text-color;
	font: normal 100% / 1.5 $text-font-stack;
	background-color: #f5f5f5;
}

.title--main {
	font-size: 64px;
	font-family: Harabara;
	letter-spacing: 1.5px;
	line-height: 1;
	margin-bottom: 1em;

	@include respond-to('md') {
		font-size: 48px;
	}
}

.title--sub {
	color: #3d3d3d;
	line-height: 1;
	font-size: 32px;
	font-weight: bold;

	@include respond-to('md') {
		font-size: 28px;
	}
}

.title--acceent {
	line-height: 1;
	font-size: 48px;
	font-weight: bold;
	color: #4a90e2;
	margin-bottom: 82px;
	display: inline-block;
	margin-top: 12px;
	@include respond-to('md') {
		font-size: 32px;
		margin-bottom: 42px;
	}
}

.text {
	font-size: 24px;
	color: #3d3d3d;
	margin-bottom: 82px;

	@include respond-to('md') {
		margin-bottom: 42px;
	}
}