// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
	font-family: Harabara;
	src: url(../fonts/Harabara.ttf);
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: Averta;
	src: url(../fonts/AvertaStack/Averta.woff);
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: Averta;
	src: url(../fonts/AvertaStack/Averta_Bold.woff);
	font-weight: bold;
	font-style: normal;
}