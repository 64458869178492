// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

html {
	box-sizing: border-box;
	height: 100%;
}

body {
	min-height: 100%;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
	color: $text-color;
	text-decoration: none;
}