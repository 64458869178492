// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.logo {
	margin-bottom: 51px;
	display: inline-flex;

	@include respond-to('lg') {
		margin-top: 32px;
		margin-bottom: 32px;
	}

	@include respond-to('md') {
		margin-top: 32px;
		margin-bottom: 32px;
	}
}

.row--flex {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include respond-to('md') {
		flex-direction: column;
	}
}

.col-text {
	width: 100%;
	max-width: 560px;

	@include respond-to('md') {
		br {
			display: none;
		}
	}
}

.col-img {
	@include respond-to('md') {
		width: 100%;
	}

	.img-wrapper {
		position: relative;
		padding-top: 32px;

		img {
			display: block;
			width: auto;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;

			@include respond-to('md') {
				position: relative;
				margin-top: -80px;
			}

			@include respond-to('sm') {
				display: none;
			}
		}

		.mom-insta {
			margin: 32px 32px 46px;
			box-shadow: 0 32px 60px 20px rgba(black, .1);

			@include respond-to('md') {
				position: relative;
				margin: -80px auto 80px !important;
			}
		}

		.nkd-insta {
			margin: 32px 32px 46px;
			box-shadow: 0 32px 60px 20px rgba(black, .1);

			@include respond-to('md') {
				position: relative;
				margin: -80px auto 80px !important;
			}
		}

		@include respond-to('md') {
			text-align: center;
			padding-top: 0;
		}
	}

	.img-badge {
		position: absolute;
		left: 0;
		top: 45%;
		border-radius: 8px;
		box-shadow: 0 10px 20px 0 rgba(248, 231, 28, 0.6);
		background-color: #f8e71c;
		padding: 16px 24px 18px;
		transform-origin: 50%;
		transform: scale(0);

		h2 {
			font-size: 48px;
			line-height: 1;
			font-weight: bold;
		}

		p {
			font-size: 24px;
			line-height: 1;
			color: rgba(0, 0, 0, 0.6);
		}

		@include respond-to('md') {
			position: relative;
			top: 0;
			left: 0;
			text-align: center;
			margin-bottom: 32px;
			display: inline-block;
			top: 350px;
			z-index: 2;
		}

		@include respond-to('sm') {
			top: 101vw;
		}
	}

	.img-badge--animated {
		animation: badgePop .35s ease-in-out;
		animation-fill-mode: both;
	}

	.img-badge--meow {
		top: 48%;


		@include respond-to('md') {

			top: 380px;
		}

		@include respond-to('sm') {
			top: 110vw;
		}
	}

	.img-badge--mom {
		top: 52%;

		@include respond-to('md') {

			top: 140px;
		}

		@include respond-to('sm') {
			top: 44vw;
		}
	}

	.img-badge--nkd {
		color: #000;
		top: 50%;

		@include respond-to('md') {

			top: 140px;
		}

		@include respond-to('sm') {
			top: 44vw;
		}
	}
}

@keyframes badgePop {
	0% {
		transform: scale(0);
	}

	80% {
		transform: scale(1.25);
	}

	100% {
		transform: scale(1);
	}
}

.links {
	display: flex;
	align-items: center;

	@include respond-to('md') {
		flex-direction: column;
		align-items: flex-start;
	}
}

.link {
	display: inline-flex;
	color: #4a90e2;
	font-size: 18px;
	font-weight: bold;
	margin: 4px 0;
}

.link--underlined {
	&:hover {
		text-decoration: underline;
	}

}

.links-divider {
	font-size: 18px;
	font-weight: bold;
	color: #969696;
	margin: 0 18px;

	@include respond-to('md') {
		display: none;
	}
}

.no-shadow-footer {
	display: none;

	@include respond-to('sm') {
		display: block;
		width: 100%;
		margin-top: -80px;
	}
}