// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
	clear: both;
	content: '';
	display: table;
}

#pageWrapper {
	display: flex;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	align-items: center;
	overflow: hidden;
	flex-direction: column;
	justify-content: center;
}

.container {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 10px;
	padding-right: 10px;
	max-width: $max-width;

	@include respond-to('lg') {
		max-width: map-get($breakpoints, md);
	}

	@include respond-to('md') {
		max-width: map-get($breakpoints, sm);
	}

	@include respond-to('sm') {
		max-width: map-get($breakpoints, xs);
	}
}